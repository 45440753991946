.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* #MarketPage-see-more {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 1)
  );
} */

.MarketPage-category::hover {
  color: black;
  margin-bottom: 20%;
}
