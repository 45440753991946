#background-image {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(https://today.tamu.edu/wp-content/uploads/2019/10/20190806_TAMU_Arerial_brs0070-Edit-2400.jpg);
  background-position: center;
  background-size: cover;
}
